import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';

import ProjectName from '../../components/ProjectName';
import Slideshow from '../../components/Slideshow';

import { Wrapper, InnerWrapper, Images, Info } from './style';
import { GlobalState, CursorContent } from '../../store';

const ProjectTemplate = ({ location, pageContext, data }) => {
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const { showThumbnails } = useContext(GlobalState.State);
  const { content } = useContext(CursorContent.State);
  const { metaData, canonicalPath } = pageContext;
  const project = get(data, 'contentfulProject');

  const images = [];
  if (project.slides) {
    project.slides.forEach(slide => {
      if (slide.image) {
        images.push(slide.image.fluid.src);
      }
      if (slide.imageLeft) {
        images.push(slide.imageLeft.fluid.src);
      }
      if (slide.imageRight) {
        images.push(slide.imageRight.fluid.src);
      }
    });
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{`${ProjectName({ project })} | ${metaData.siteName}`}</title>
        <meta property="og:title" content={ProjectName({ project })} />
        {images.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}
        {canonicalPath ? (
          <link rel="canonical" href={location.origin + canonicalPath} />
        ) : null}
      </Helmet>
      <InnerWrapper>
        <Images>
          <Slideshow slides={project.slides} />
        </Images>
        <Info>
          <p>
            <a
              onClick={evt => {
                evt.preventDefault();
                globalStateDispatch({
                  type: 'set',
                  showThumbnails: !showThumbnails,
                });
              }}
              style={{ position: 'relative', zIndex: 9999 }}
            >
              {showThumbnails ? 'Close' : 'Overview'}
            </a>{' '}
            — {content.slideshowCounterNext}
          </p>
          <h1>
            {project.caption && project.caption.length > 0 ? (
              project.caption
            ) : (
              <ProjectName project={project} />
            )}
          </h1>
        </Info>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ProjectTemplate;

export const projectQuery = graphql`
  query ProjectBySlug($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      name
      client
      caption
      category {
        name
      }
      slides {
        ... on ContentfulProjectSlide1Up {
          id
          image {
            id
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 3000, maxHeight: 3000, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          vimeoId
        }
        ... on ContentfulProjectSlide2Up {
          id
          imageLeft {
            id
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 3000, maxHeight: 3000, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          imageRight {
            id
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 3000, maxHeight: 3000, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
