import React, { useContext, useEffect, useState } from 'react';
import Image from 'gatsby-image';
import { CursorContent, GlobalState } from '../../store';
import Slide from '../Slide';
import {
  Wrapper,
  Nav,
  Link,
  Spacer,
  Slides,
  Thumbnails,
  ThumbnailsContainer,
} from './style';
import { breakpoints } from '../../theme';

const thumbnailDimensions = ({ file }, areaOverride) => {
  const area = areaOverride || 50;
  let height = Math.sqrt(
    area * (file.details.image.height / file.details.image.width)
  );
  let width = Math.sqrt(
    area * (file.details.image.width / file.details.image.height)
  );

  return {
    height: `${height}rem`,
    width: `${width}rem`,
  };
};

const Slideshow = ({ slides }) => {
  const cursorContentDispatch = useContext(CursorContent.Dispatch);
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const { showThumbnails } = useContext(GlobalState.State);
  const [activeIndex, setActiveIndex] = useState(0);

  const thumbnailArea =
    typeof window !== 'undefined' && window.innerWidth <= breakpoints.MOBILE
      ? 25
      : undefined;

  const slidesCount = slides ? slides.length : 0;

  let previousIndex = activeIndex - 1;
  if (previousIndex < 0) previousIndex = slidesCount - 1;

  let nextIndex = activeIndex + 1;
  if (nextIndex > slidesCount - 1) nextIndex = 0;

  const activeSlideIsVideo = !!slides[activeIndex].vimeoId;

  useEffect(() => {
    cursorContentDispatch({
      type: 'setContentByType',
      contentType: 'slideshowCounterNext',
      content: `${activeIndex + 1}/${slidesCount}`,
    });
    cursorContentDispatch({
      type: 'setContentByType',
      contentType: 'slideshowCounterPrevious',
      content: `${activeIndex + 1}/${slidesCount}`,
    });
    return () => {
      cursorContentDispatch({
        type: 'setContentByType',
        contentType: 'slideshowCounter',
        content: null,
      });
    };
  }, [activeIndex, slidesCount]);

  const handleKeyPress = evt => {
    switch (evt.keyCode) {
      case 27:
        globalStateDispatch({
          type: 'set',
          showThumbnails: false,
        });
        break;
      case 37:
        setActiveIndex(previousIndex);
        break;
      case 39:
        setActiveIndex(nextIndex);
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [activeIndex]);

  return (
    <Wrapper>
      {slides && slidesCount > 1 ? (
        <Nav>
          <Link
            onMouseMove={() =>
              cursorContentDispatch({
                type: 'setActiveType',
                activeType: 'slideshowCounterPrevious',
              })
            }
            onMouseLeave={() =>
              cursorContentDispatch({
                type: 'setActiveType',
                activeType: null,
              })
            }
            onClick={evt => {
              evt.preventDefault();
              setActiveIndex(previousIndex);
            }}
          />
          {activeSlideIsVideo ? <Spacer /> : null}
          <Link
            onMouseMove={() =>
              cursorContentDispatch({
                type: 'setActiveType',
                activeType: 'slideshowCounterNext',
              })
            }
            onMouseLeave={() =>
              cursorContentDispatch({
                type: 'setActiveType',
                activeType: null,
              })
            }
            onClick={evt => {
              evt.preventDefault();
              setActiveIndex(nextIndex);
            }}
          />
        </Nav>
      ) : null}
      <Slides>
        {slides
          ? slides.map((slide, index) => (
              <Slide
                key={slide.id}
                isActive={index === activeIndex}
                slide={slide}
              />
            ))
          : null}
      </Slides>
      <Thumbnails
        isVisible={showThumbnails}
        onClick={evt => {
          evt.preventDefault();
          globalStateDispatch({
            type: 'set',
            showThumbnails: false,
          });
        }}
      >
        <ThumbnailsContainer>
          {slides
            ? slides
                .reduce((images, slide, index) => {
                  if (slide.image)
                    images.push({ ...slide.image, slideIndex: index });
                  if (slide.imageLeft)
                    images.push({ ...slide.imageLeft, slideIndex: index });
                  if (slide.imageRight)
                    images.push({ ...slide.imageRight, slideIndex: index });
                  return images;
                }, [])
                .map(image => (
                  <li key={image.id}>
                    <a
                      onClick={evt => {
                        evt.preventDefault();
                        setActiveIndex(image.slideIndex);
                        globalStateDispatch({
                          type: 'set',
                          showThumbnails: false,
                        });
                      }}
                    >
                      <Image
                        fluid={image.fluid}
                        style={thumbnailDimensions(image, thumbnailArea)}
                      />
                    </a>
                  </li>
                ))
            : null}
        </ThumbnailsContainer>
      </Thumbnails>
    </Wrapper>
  );
};

export default Slideshow;
