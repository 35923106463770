import styled from 'styled-components';

import { breakpoints, layout, typography, colors } from '../../theme';
import { rgba } from 'polished';

export const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  user-select: none;
  top: 0;
`;

export const Nav = styled.nav`
  bottom: -${layout.SPACING_Y * 2 + typography.LINE_HEIGHT}rem;
  cursor: none;
  display: flex;
  left: -${layout.SPACING_X}rem;
  position: absolute;
  right: -${layout.SPACING_X}rem;
  top: -${layout.SPACING_Y * 2 + typography.LINE_HEIGHT}rem;
  z-index: 1;
`;

export const Link = styled.a`
  cursor: none;
  flex-grow: 1;
  pointer-events: auto;
`;

export const Spacer = styled.span`
  cursor: default;
  width: 5rem;

  @media ${breakpoints.MOBILE_MQ} {
    display: none;
  }
`;

export const Slides = styled.div`
  bottom: 4vh;
  left: 4vw;
  position: absolute;
  right: 4vw;
  top: 4vh;

  @media ${breakpoints.MOBILE_MQ} {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  @media ${breakpoints.DESKTOP_MQ} {
    bottom: 6vh;
    left: 6vw;
    right: 6vw;
    top: 6vh;
  }
`;

export const Thumbnails = styled.div`
  align-items: center;
  background: ${rgba(colors.WHITE, 0.95)};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  position: absolute;
  right: 0;
  top: 0;
  transition: ${props =>
    props.isVisible
      ? 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out'
      : 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out'};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  z-index: 2;

  @media ${breakpoints.MOBILE_MQ} {
    left: -${layout.SPACING_X / 2}rem;
    right: -${layout.SPACING_X / 2}rem;
  }
`;

export const ThumbnailsContainer = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80rem;

  li {
    margin: ${layout.SPACING / 2}rem;

    @media ${breakpoints.MOBILE_MQ} {
      margin: ${layout.SPACING / 4}rem;
    }
  }

  a {
    cursor: pointer;
  }
`;
